<template>
  <explanation-icon
    name="Legenda dos ícones"
    tooltip-text="Legendas dos ícones"
  >
    <v-row
      class="flex-column pa-5"
      justify="space-between"
      style="height: 150px"
    >
      <div><v-icon>radio_button_unchecked</v-icon> Par não checado</div>
      <div>
        <v-icon color="primary">radio_button_unchecked</v-icon>
        Par já checado
      </div>
      <div>
        <v-icon color="primary">radio_button_checked</v-icon> Par adicionado ao
        diagnóstico
      </div>
    </v-row>
  </explanation-icon>
</template>

<script>
export default {
  name: "Legends",
};
</script>

<style scoped></style>
